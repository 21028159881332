import { isAndroid, isIOS, isMobile } from "react-device-detect";
import logo from "./assets/images/logo.png";
import person from "./assets/images/person.png";
import styled from "styled-components";

const App = () => {
	const goToApp = () => {
		const { hostname, pathname } = window.location;
		if (isAndroid && pathname.length > 1) {
			window.location.replace(
				`intent://${
					hostname + pathname
				}/#Intent;scheme=https;package=com.foresterapp;end`
			);
		}
		if (isIOS && pathname.length > 1) {
			window.location.replace(window.location.href);
		}
	};

	const installIOS = () => {
		window.location.replace(
			"https://apps.apple.com/ua/app/forester/id1607048411?l=uk/"
		);
	};

	const openApp = () => {
		if (window.location.pathname.length > 1) {
			window.location.replace(
				`foresterapp://applinks.openforestprotocol.org/${window.location.pathname}`
			);
		}
	};

	const renderTitle = () => {
		if (!isMobile) {
			return "Hello! All you need to do is open this link on your mobile phone.";
		} else if (isAndroid) {
			return "Hello! All you need to do is click the button to download the Forester App.";
		} else if (isIOS) {
			return "Hello! All you need to do is click the button to go to the Forester App or download the app.";
		}
	};

	return (
		<Container>
			<Logo src={logo} alt="logo" />
			<Title>{renderTitle()}</Title>
			<Description>
				In the app on your{" "}
				{!isMobile ? <BoldText>mobile phone</BoldText> : "mobile phone"}
				, you should be able to see the forest(s) you are assigned as
				soon as you do this.
			</Description>

			{isMobile && isAndroid && (
				<Button onClick={goToApp}>
					<Person src={person} alt="person" />
					<ButtonTitle>Go to the Forester app</ButtonTitle>
				</Button>
			)}
			{isMobile && isIOS && (
				<>
					<Button onClick={openApp}>
						<Person src={person} alt="person" />
						<ButtonTitle>Go to the Forester app</ButtonTitle>
					</Button>
					<Button onClick={installIOS}>
						<ButtonTitle>Install Forester app</ButtonTitle>
					</Button>
				</>
			)}
		</Container>
	);
};

const Container = styled.div`
	background-color: #fff;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	padding: 0 30px;
	text-align: center;
`;

const Logo = styled.img`
	height: 45px;
	margin-bottom: 26px;
	@media only screen and (max-width: 768px) {
		height: 50px;
		margin-bottom: 15px;
	}
`;

const Title = styled.span`
	font-family: "Poppins";
	font-weight: 700;
	font-size: 36px;
	line-height: 48px;
	color: #1c505d;
	max-width: 800px;

	@media only screen and (max-width: 768px) {
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 20px;
	}
`;
const Description = styled.span`
	font-family: "Poppins";
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
	color: #5b6075;
	margin-bottom: 28px;
	max-width: 800px;

	@media only screen and (max-width: 768px) {
		font-size: 16px;
		line-height: 30px;
		margin-bottom: 28px;
	}
`;
const BoldText = styled.span`
	font-weight: bold;
`;

const Person = styled.img`
	height: 32px;
	width: 32px;
	margin-right: 4px;
`;
const Button = styled.div`
	width: 328px;
	height: 48px;
	background: #329d9c;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-bottom: 20px;
`;
const ButtonTitle = styled.span`
	font-family: "Poppins";
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	text-transform: uppercase;
	color: #ffffff;
`;

export default App;
